import React from 'react'

function Content2() {
    return (
        <div className="contact-me-iCons-container">
            <div className="content-content2-link">
                <a href="https://gitlab.com/ErinDeji" target="_blank" rel="noreferrer"><span className="contact-me-icon2 iconify" data-icon="bx:bxl-gitlab" data-inline="false"></span></a>
                <a href="https://github.com/erinoggz" target="_blank" rel="noreferrer"><span className="contact-me-icon2 iconify" data-icon="raphael:githubalt" data-inline="false"></span></a>
                <a href="https://www.linkedin.com/in/erin-deji/" target="_blank" rel="noreferrer"><span className="contact-me-icon2 iconify" data-icon="ant-design:linkedin-filled" data-inline="false"></span></a>
                <a href="https://twitter.com/ande_oggz" target="_blank" rel="noreferrer"><span className="contact-me-icon2 iconify" data-icon="uim:twitter" data-inline="false"></span></a>
                <a href="https://www.instagram.com/ande_lifa/" target="_blank" rel="noreferrer"><span className="contact-me-icon2 iconify" data-icon="ant-design:instagram-filled" data-inline="false"></span></a>
                <a href="https://drive.google.com/file/d/1Q6As274gq7rfmpUCfnGw4L5uW54NEUNr/view?usp=sharing" target="_blank" rel="noreferrer"><span className="contact-me-icon2 iconify" data-icon="ant-design:file-pdf-filled" data-inline="false"></span></a>
                <a href="mailto:erin.deji@gmail.com?subject=Hello Erin" target="_blank" rel="noreferrer" ><span className="contact-me-icon2 iconify" data-icon="mdi:gmail" data-inline="false"></span></a>
                <a href="https://tinyurl.com/erindeji" target="_blank" rel="noopener noreferrer"><span className="contact-me-icon2 iconify" data-icon="ph:whatsapp-logo-fill" data-inline="false"></span></a>
            </div>
        </div>
    )
}

export default Content2
