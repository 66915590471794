
export const ProjectData1 = [
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439758/my-portfolio/Screenshot_2021-12-02_at_11.07.18_vvebzl.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439307/my-portfolio/Screenshot_2021-12-02_at_10.59.19_xmv6vd.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439307/my-portfolio/Screenshot_2021-12-02_at_11.00.55_cz8nvl.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439304/my-portfolio/Screenshot_2021-12-02_at_10.59.33_nzgu9l.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439302/my-portfolio/Screenshot_2021-12-02_at_10.59.48_zjpnyc.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439302/my-portfolio/Screenshot_2021-12-02_at_11.00.41_vhj72l.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439302/my-portfolio/Screenshot_2021-12-02_at_11.00.00_dehfpv.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439302/my-portfolio/Screenshot_2021-12-02_at_11.00.16_h3odaz.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439765/my-portfolio/Screenshot_2021-12-02_at_11.08.36_xpydxf.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439762/my-portfolio/Screenshot_2021-12-02_at_11.06.29_dplkly.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439760/my-portfolio/Screenshot_2021-12-02_at_11.06.56_hbqc6k.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439760/my-portfolio/Screenshot_2021-12-02_at_11.05.54_lbqpw6.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439760/my-portfolio/Screenshot_2021-12-02_at_11.08.46_vylqud.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439760/my-portfolio/Screenshot_2021-12-02_at_11.08.27_i72ci9.png",
        url: "https://new.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638439758/my-portfolio/Screenshot_2021-12-02_at_11.06.41_brvvm1.png",
        url: "https://new.konga.com/"
    }];

export const ProjectData5 = [
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638441469/my-portfolio/Screenshot_2021-12-02_at_11.37.13_djoixl.png",
        url: "https://www.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638441470/my-portfolio/Screenshot_2021-12-02_at_11.35.51_jhefgo.png",
        url: "https://www.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638441470/my-portfolio/Screenshot_2021-12-02_at_11.36.03_szwkqs.png",
        url: "https://www.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638441474/my-portfolio/Screenshot_2021-12-02_at_11.36.53_gttdqn.png",
        url: "https://www.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638441469/my-portfolio/Screenshot_2021-12-02_at_11.36.07_yxzfww.png",
        url: "https://www.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638441469/my-portfolio/Screenshot_2021-12-02_at_11.36.47_pez3in.png",
        url: "https://www.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638441468/my-portfolio/Screenshot_2021-12-02_at_11.36.25_qncq4r.png",
        url: "https://www.konga.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638441469/my-portfolio/Screenshot_2021-12-02_at_11.36.35_esviqx.png",
        url: "https://www.konga.com/"
    }];


export const ProjectData6 = [
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638444852/my-portfolio/Screenshot_1638444576_z34ttf.png",
        // url: "http://iqstore.ivyarc.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638444851/my-portfolio/Screenshot_1638444617_zx1tro.png",
        // url: "http://iqstore.ivyarc.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638444852/my-portfolio/Screenshot_1638444628_jigjpz.png",
        // url: "http://iqstore.ivyarc.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638444852/my-portfolio/Screenshot_1638444652_rqx9hg.png",
        // url: "http://iqstore.ivyarc.com/"
    }, {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638444851/my-portfolio/Screenshot_1638444637_swohz9.png",
        // url: "http://iqstore.ivyarc.com/"
    },
    {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638444851/my-portfolio/Screenshot_1638444674_azcf97.png",
        // url: "http://iqstore.ivyarc.com/"
    }, {
        projIMG: "https://res.cloudinary.com/oggz/image/upload/v1638444852/my-portfolio/Screenshot_1638444754_ahxpsc.png",
        // url: "http://iqstore.ivyarc.com/"
    }];