import React from 'react'
import { motion } from "framer-motion"


function Content1() {
    return (
        <div>
            <div className="card-wrapper">
                <div>
                    <div className="about-page-name">Erinfolami Ayodeji Rasaq</div>
                    <div>Software Engineer</div>
                </div>
                <motion.div
                    className="img-img-container"
                    initial={{ y: "-200vw" }}
                    animate={{ y: 0 }}
                    transition={{
                        delay: 0.5,
                        type: "spring",
                        stiffness: 100,
                        mass: 0.4,
                        damping: 8
                    }}
                >
                    <img className="Profile-img" alt="profileIMG" src="https://res.cloudinary.com/oggz/image/upload/v1638436617/my-portfolio/ande_lifa_20211126_1_zgkjz8.jpg" />
                </motion.div>
            </div>
        </div>
    )
}

export default Content1
